import PropTypes from "prop-types";
import React, { Component } from "react";
import { Map, Marker, TileLayer } from "react-leaflet";
import Control from "react-leaflet-control";
import { connect } from "react-redux";
import { Link, Prompt, Redirect, withRouter } from "react-router-dom";
import { Alert, Badge, Button, Col, Row } from "reactstrap";
import { redirect } from "../../actions/authActions";
import { getDraft } from "../../actions/draftActions";
import { clearErrors, clearOneError } from "../../actions/errorActions";
import {
	approveProject,
	archiveProject,
	createERTable,
	createProject,
	deleteProject,
	featureProject,
	getUserProjects,
	getUserProjectTokens,
	rejectProject,
	reopenProject,
	submitProject,
	updateEmissions,
	getPublicProjects,
} from "../../actions/projectActions";
import ContentBox from "../../components/common/ContentBox";
import DatePickerGroup from "../../components/common/DatePickerGroup";
import FileUploadFieldGroup from "../../components/common/FileUploadFieldGroup";
import LocationPicker from "../../components/common/LocationPicker";
import SelectListGroup from "../../components/common/SelectListGroup";
import { ShareButton } from "../../components/common/ShareButton";
import SmallSpinner from "../../components/common/SmallSpinner";
import Spinner from "../../components/common/Spinner";
import TextAreaFieldGroup from "../../components/common/TextAreaFieldGroup";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import Tooltip from "../../components/common/Tooltip";
import EmissionReduction from "../../components/emissionReduction/EmissionReduction";
import { getEmissionsArray, isEmissionsChanged } from "../../lib/Emissions";
import { ProgramTypeEnum, ProjectStatusEnum } from "../../lib/Enums";
import { TextDictionary } from "../../lib/TextDictionary";

function ImageCol(props) {
	return (
		<Col sm="6" xl="4">
			{props.children}
		</Col>
	);
}

class CreateProject extends Component {
	constructor(props) {
		super(props);
		this.state = this.getDefaultState();

		this.onChange = this.onChange.bind(this);
		this.onChangeDocument = this.onChangeDocument.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.timelineUpdated = this.timelineUpdated.bind(this);
		this.timelineYearUpdated = this.timelineYearUpdated.bind(this);
		this.onErChange = this.onErChange.bind(this);
		this.onDraftSubmit = this.onDraftSubmit.bind(this);
		this.erRange = this.erRange.bind(this);
		this.isNewProject = this.isNewProject.bind(this);
	}

	getDefaultState() {
		return {
			id: "",
			title: "",
			description: "",
			offsetProvider: "",
			visibility:
				this.props.programType === ProgramTypeEnum.Inventory ||
					this.props.programType === ProgramTypeEnum.PurchasedOffsets
					? "Private"
					: "Public",
			overviewTracking: "Tracked",
			featured: {},
			projectSite: {},
			before: {},
			activity: {},
			otherImage: {},
			mapOfActivities: {},
			programType: this.props.programType ? this.props.programType : "",
			projectType: "",
			projectStatus: "",
			programOfActivities: "",
			startDate:
				this.props.programType === ProgramTypeEnum.Inventory
					? (new Date().getFullYear() - 1).toString()
					: "",
			endDate:
				this.props.programType === ProgramTypeEnum.Inventory
					? (new Date().getFullYear() - 1).toString()
					: "",
			address: "",
			latitude: "",
			longitude: "",
			fullName: "",
			jobTitle: "",
			email: "",
			tel: "",
			projectPlan: {},
			financialPlan: {},
			projectValidation: {},
			verificationReport: {},
			other1: {},
			other2: {},
			documents: {},

			erLastUpdated: "",
			erRow: [],
			errors: {},
			erRange: {},
			projectSerial: "",
			status: ProjectStatusEnum.Draft,
			emissionReductions: { draft: [], registered: [] },
			//inventoryEmissionReductions: { draft: [], registered: [] },
			isFeatured: false,
			isArchived: false,
			changesPending: false,
			working_draft: false,
			working_submit: false,
			editOverwrite: false,
		};
	}

	isNewProject() {
		return this.props.match.path !== "/profile/drafts/:id";
	}

	componentDidMount() {
		this.props.getUserProjectTokens();
		this.props.getUserProjects(this.props.auth.user.certserialnumber);
		this.props.clearErrors();
		this.props.getPublicProjects();
		if (this.isNewProject()) {
			//clear the state to show empty boxes for the create
			this.setState(this.getDefaultState());

			// update emissions and documents
			if (this.props.programType === ProgramTypeEnum.Inventory)
				this.erRange(this.state.startDate.substring(0, 4), this.state.endDate.substr(0, 4));
		} else {
			this.props.getDraft(this.props.match.params.id);
		}
	}
	componentDidUpdate(prevProps) {
		// if(this.props.match.params.id !== prevProps.match.params.id) {
		// 	// the project id changed, reload the project
		// 	this.props.getDraft(this.props.match.params.id, this.props.auth.user.id);
		// }

		if (
			!this.isNewProject() &&
			prevProps.project.project !== this.props.project.project &&
			this.props.project.project //&&
			//this.props.project.project.pictures
		) {
			const project = this.props.project.project;
			const {
				projectPlan,
				financialPlan,
				projectValidation,
				verificationReport,
				other1,
				other2,
				...documents
			} = project.documents;

			this.setState(
				{
					id: project._id,
					title: project.title,
					description: project.description,
					offsetProvider: project.offsetProvider,
					visibility: project.visibility,
					overviewTracking: project.overviewTracking,

					// pictures
					featured: project.pictures.featured,
					projectSite: project.pictures.projectSite,
					before: project.pictures.before,
					activity: project.pictures.activity,
					otherImage: project.pictures.otherImage,
					mapOfActivities: project.pictures.mapOfActivities,

					programType: project.programType,
					projectType: project.projectType,
					projectStatus: project.projectStatus,
					programOfActivities: project.programOfActivities,
					funding: project.funding,

					// Tiemline
					startDate: project.timeline.startDate,
					endDate: project.timeline.endDate,

					address: project.location.address,
					latitude: project.location.latitude,
					longitude: project.location.longitude,

					fullName: project.contact.fullName,
					jobTitle: project.contact.jobTitle,
					email: project.contact.email,
					tel: project.contact.tel,

					// Documents
					projectPlan: project.documents.projectPlan,
					financialPlan: project.documents.financialPlan,
					projectValidation: project.documents.projectValidation,
					verificationReport: project.documents.verificationReport,
					other1: project.documents.other1,
					other2: project.documents.other2,
					documents: documents,

					erLastUpdated: project.erLastUpdated,
					erRow: project.erRow,
					projectSerial: project.projectSerial,
					status: project.status,

					// Emission Reductions
					emissionReductions: {
						draft: project.emissionReductions.draft,
						registered: project.emissionReductions.registered,
					},

					accountHolder: project.accountHolder,

					isFeatured: project.isFeatured,
					isArchived: project.isArchived,
					changesPending: false,
				},
				() => {
					if (this.state.start) {
						this.props.createERTable(this.state.start, this.currentDate());
					}
				}
			);
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
	}

	timelineUpdated(e) {
		this.setState({ [e.target.name]: e.target.value, changesPending: true }, () => {
			this.erRange(this.state.startDate.substring(0, 4), this.state.endDate.substr(0, 4));
		});
		if (this.state.errors[e.target.name]) this.props.clearOneError(e.target.name);
	}

	timelineYearUpdated(e) {
		const intValue = parseInt(e.target.value);
		if (e.target.value !== "" && isNaN(intValue)) return;

		if (e.target.value.length > 4) return;

		this.timelineUpdated(e);
	}

	locationUpdated(e) {
		this.setState({
			latitude: e.latitude,
			longitude: e.longitude,
			changesPending: true,
		});

		if (this.state.errors["latitude"]) this.props.clearOneError("latitude");
		if (this.state.errors["longitude"]) this.props.clearOneError("longitude");
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value, changesPending: true });
		if (this.state.errors[e.target.name]) this.props.clearOneError(e.target.name);
	}

	onChangeDocument(e) {
		this.setState({
			documents: { ...this.state.documents, [e.target.name]: e.target.value },
			changesPending: true,
		});
		if (this.state.errors[e.target.name]) this.props.clearOneError(e.target.name);
	}

	isErRangeValid(startYear, endYear) {
		const diff = endYear - startYear;

		const maxYears =
			this.state.programType !== "Infrastructure Canada" &&
				this.state.programType !== ProgramTypeEnum.Inventory
				? 20
				: 100;
		// only use reasonable values
		if (diff > maxYears || diff < 0) return false;
		if (!startYear || !endYear) return false;

		return true;
	}

	// getEmissionReductions() {
	// 	if (this.state.programType == "Inventory") return this.state.inventoryEmissionReductions;
	// 	else return this.state.emissionReductions;
	// }

	updateInventoryDocuments(startYear, endYear) {
		if (!this.isErRangeValid(startYear, endYear)) return;

		const diff = endYear - startYear;
		const range = [...Array(diff + 1)].map((i) => startYear++);

		// add years
		const newDocuments = { ...this.state.documents };
		for (let y in range) {
			const name = "_inventory_" + range[y];
			if (!newDocuments[name]) {
				//console.log("Adding " + name);
				newDocuments[name] = {};
			}
		}

		// remove years
		const rangeNames = range.map((y) => "_inventory_" + y);
		for (let name in newDocuments) {
			if (name.startsWith("_inventory_") && rangeNames.indexOf(name) < 0) {
				//console.log("Removing " + name);
				delete newDocuments[name];
			}
		}

		return newDocuments;
	}

	erRange(startYear, endYear) {
		const registered = this.state.emissionReductions.registered;
		var drafts = this.state.emissionReductions.draft;
		const diff = endYear - startYear; // moment().format("YYYY")
		var documents = this.state.documents;
		// only use reasonable values
		if (!this.isErRangeValid(startYear, endYear)) return;

		if (this.state.programType === "Inventory") {
			documents = this.updateInventoryDocuments(startYear, endYear);

			//drafts = drafts.filter(x => x.status === "Inventory");
		} else if (this.state.programType === "Infrastructure Canada") {
			drafts = drafts.filter((x) => x.status === "Estimated" || x.status === "Reduced");
		}

		const range = [...Array(diff + 1)].map((i) => startYear++);

		const usedYears = drafts.map((x) => x.year).concat(registered.map((x) => x.year));

		const yearsToAdd = range.filter((a) => !usedYears.includes(a));

		// remove old lines
		drafts = drafts.filter((x) => range.indexOf(x.year) >= 0);

		let newDrafts;
		if (this.state.programType === "Inventory") {
			// add to inventory project
			newDrafts = yearsToAdd
				.map((x) => ({
					year: x,
					status: "Inventory",
					quantity: 0,
				}))
				.concat(drafts);
		} else if (this.state.programType === "Infrastructure Canada") {
			// add to Climate Lense project
			newDrafts = yearsToAdd
				.map((x) => [
					{
						year: x,
						status: "Estimated",
						quantity: 0,
					},
					{
						year: x,
						status: "Reduced",
						quantity: 0,
					},
				])
				.reduce(function (a, b) {
					return a.concat(b);
				})
				.concat(drafts);
		} else {
			// add new lines to the emission reductions

			newDrafts = yearsToAdd
				.map((x) => ({
					year: x,
					status: "Projected",
					quantity: 0,
				}))
				.concat(drafts);
		}

		newDrafts.sort((a, b) => parseFloat(a.year) - parseFloat(b.year));
		this.setState({
			emissionReductions: {
				registered: registered,
				draft: newDrafts,
			},

			documents: documents,
		});

		// if (diffRange.length > 0) {
		// 	newDraft = newDraft.filter(function(obj) {
		// 		return !this.has(obj.year);
		// 	}, new Set(diffRange.map(obj => obj.year)));
		// }
		// else if (range.length > newRange.length) {
		// 	range.map(a => {
		// 		// TODO look into reducing
		// 		if (!this.state.erRow.some(b => b.year === a.year)) {
		// 			return newRange.push(a);
		// 		} else {
		// 			return newRange;
		// 		}
		// 	});
		// }
	}
	onErChange(newData) {
		const changed = isEmissionsChanged(this.state.emissionReductions, newData);

		if (changed) {
			this.setState({ emissionReductions: newData, changesPending: true });
		} else {
			this.setState({ emissionReductions: newData });
		}

		// clearing error is currently nor working as it will collapse the years opened by errors
		//if (this.state.errors.emissionReductions) this.props.clearOneError("emissionReductions");
	}

	submit(draft) {
		const projectData = {
			title: this.state.title,
			description: this.state.description,
			offsetProvider: this.state.offsetProvider,
			visibility: this.state.visibility,
			overviewTracking: this.state.overviewTracking,
			pictures: {
				featured: this.state.featured,
				projectSite: this.state.projectSite,
				before: this.state.before,
				activity: this.state.activity,
				otherImage: this.state.otherImage,
				mapOfActivities: this.state.mapOfActivities,
			},
			programType: this.state.programType,
			projectType: this.state.projectType,
			projectStatus: this.state.projectStatus,
			programOfActivities: this.state.programOfActivities,
			funding: this.state.funding,
			timeline: {
				startDate: this.state.startDate,
				endDate: this.state.endDate,
			},
			location: {
				address: this.state.address,
				latitude: isNaN(this.state.latitude) ? null : this.state.latitude,
				longitude: isNaN(this.state.longitude) ? null : this.state.longitude,
			},
			contact: {
				fullName: this.state.fullName,
				jobTitle: this.state.jobTitle,
				email: this.state.email,
				tel: this.state.tel,
			},
			documents: {
				projectPlan: this.state.projectPlan,
				financialPlan: this.state.financialPlan,
				projectValidation: this.state.projectValidation,
				verificationReport: this.state.verificationReport,
				other1: this.state.other1,
				other2: this.state.other2,
				...this.state.documents,
			},
			erRow: this.state.erRow,
			projectSerial: this.state.projectSerial,
			erLastUpdated: this.state.erLastUpdated,

			emissionReductions: {
				draft: this.state.emissionReductions.draft,
				registered: this.state.emissionReductions.registered,
			},
		};
		if (draft) {
			this.setState({ working_draft: true, changesPending: false }, () => {
				this.props.createProject(projectData, this.props.history).then(() => {
					this.setState({
						working_draft: false,
					});
				});
			});
		} else {
			this.setState({ working_submit: true, changesPending: false });
			this.props.submitProject(projectData, this.props.history).then((serial) => {
				this.setState({
					projectSerial: serial,
					working_submit: false,
				});
			});
		}
	}
	onDraftSubmit(e) {
		e.preventDefault();
		this.submit(true);
	}
	onSubmit(e) {
		e.preventDefault();
		this.submit(false);
	}

	render() {
		const { errors } = this.state;
		const { projectTokens, inventoryTokens, loading, tokens_loading, publicProjects } =
			this.props.project;

		const isInventory = this.state.programType === ProgramTypeEnum.Inventory;
		const isTransferred = this.state.programType === ProgramTypeEnum.TransferredOffsets;
		const isOffsets = this.state.programType === ProgramTypeEnum.PurchasedOffsets || isTransferred;
		const isInventoryOrOffsets = isInventory || isOffsets || isTransferred;
		const isSpecialProject =
			isInventoryOrOffsets || this.state.programType === "Infrastructure Canada";

		const isDateSet = this.state.startDate && this.state.endDate;

		var programTypeOptions = [
			//{ label: "BC GCC", value: "BC GCC" },
			{ label: "BC GCC Option 1", value: "BC GCC Option 1" },
			{ label: "BC GCC Option 2", value: "BC GCC Option 2" },
			{ label: "FCM PCP", value: "FCM" },
			{ label: "Compact of Mayors", value: "Compact Of Mayors" },
			{ label: "Comox Project", value: "Comox Project" },
			{ label: "Climate Lens", value: "Infrastructure Canada", disabled: isDateSet },
			{ label: "ISO 14064-2", value: "ISO 14064-2" },
			{ label: "Gold Standard", value: ProgramTypeEnum.GoldStandard },
			{ label: "VCS", value: ProgramTypeEnum.VCS },
			{ label: "Other", value: ProgramTypeEnum.Other },
		];
		if (this.props.programType === "Inventory") {
			programTypeOptions = [{ label: "Inventory", value: "Inventory", disabled: isDateSet }];
		}

		if (this.props.programType === ProgramTypeEnum.PurchasedOffsets) {
			programTypeOptions = [
				{ label: "Offsets", value: ProgramTypeEnum.PurchasedOffsets, disabled: isDateSet },
			];
		}

		const typeOptions = [
			{ label: "Energy Generation", value: "Energy Generation" },
			{ label: "Energy Efficiency", value: "Energy Efficiency" },
			{ label: "Waste", value: "Waste" },
			{ label: "Fleet", value: "Fleet" },
			{ label: "Transportation", value: "Transportation" },
			{ label: "Natural Assets", value: "Natural Assets" },
			{ label: "Infrastructure", value: "Infrastructure" },
			{ label: "Others", value: "Others" },
		];
		const statusOptions = [
			{ label: "Planning", value: "Planning" },
			{ label: "Active", value: "Active" },
			{ label: "Inactive", value: "Inactive" },
		];
		if (!isInventoryOrOffsets) {
			statusOptions.splice(1, 0, { label: "Proposed - Seeking Funding", value: "Proposed" });
		}

		const programOfActivitiesOptions = [
			{ label: "Single Project", value: "Single Project" },
			{ label: "Program of Activities", value: "Program of Activities" },
		];

		if (loading || tokens_loading || projectTokens === null || publicProjects == null)
			return <Spinner />;

		if (isInventoryOrOffsets) {
			if (this.isNewProject() && inventoryTokens <= 0) {
				return <Redirect to="/inventory/pay" />;
			}
		} else {
			if (this.isNewProject() && projectTokens <= 0) {
				return <Redirect to="/project/pay" />;
			}
		}

		const editOverwrite = this.state.editOverwrite;

		var badge = null;
		if (this.state.status === ProjectStatusEnum.Draft) {
			badge = <Badge color="danger">Draft</Badge>;
		} else if (this.state.status === ProjectStatusEnum.Submitted) {
			badge = (
				<Badge color="warning" id="submittedBadge">
					Submitted
				</Badge>
			);
		}
		if (this.state.status === ProjectStatusEnum.Rejected) {
			badge = <Badge color="danger">Rejected</Badge>;
		}

		const fieldPrefix = isInventoryOrOffsets ? "" : "Project ";
		var displayTitle = isInventory
			? "Register Inventory"
			: isOffsets
				? "Register Purchased Offsets"
				: "Register New Project";
		if (this.state.status !== ProjectStatusEnum.Draft) {
			displayTitle = this.state.title;
		}

		// prevent editing when it's not allowd to save
		const ownProject =
			this.props.auth.user.certserialnumber === this.state.accountHolder ||
			this.state.accountHolder === undefined;

		// only change our own drafts
		var readOnly =
			!(
				(ownProject || editOverwrite) &&
				(this.state.status === ProjectStatusEnum.Draft ||
					this.state.status === ProjectStatusEnum.Rejected)
			) &&
			!this.isNewProject() &&
			!isInventoryOrOffsets;


		// only change emissions for our own drafts or approved projects
		var readOnlyEmission = !(
			(ownProject || editOverwrite) &&
			[
				ProjectStatusEnum.Draft,
				ProjectStatusEnum.Rejected,
				ProjectStatusEnum.Approved,
			].indexOf(this.state.status) >= 0
		);

		var disableEndDate = readOnlyEmission;
		var readOnlyDocuments = readOnlyEmission;



		if (isTransferred) {
			readOnly = true;
			readOnlyEmission = false;
			disableEndDate = true;
			readOnlyDocuments = true;
		}

		//readOnly = true;

		const isFeatured = this.state.isFeatured;
		const isArchived = this.state.isArchived;

		const documents = ["projectValidation", "verificationReport", "other1", "other2"];

		let docsChanged;
		if (!this.props.project.project || !this.props.project.project.documents) {
			docsChanged = false;
		} else {
			docsChanged = documents.some((x) => {
				return this.props.project.project.documents[x].url !== this.state[x].url;
			});
		}

		const serializationRequired = this.state.emissionReductions.draft.some((x) => {
			// if(x.status !== "Projected") {
			// 	console.log(x)
			// }
			return x.status !== "Projected";
		});
		if (!this.props.project) return "";

		const inventoryEmmisions = !this.props.project.projects
			? []
			: getEmissionsArray(
				this.props.project.projects
					.filter((x) => x.projectSerial !== this.state.projectSerial)
					// add current emission reductions to projects to get correct statistics
					.concat({
						emissionReductions: this.state.emissionReductions,
						status: ProjectStatusEnum.Approved,
					}),
				true /* includeDraftProjects */
			);

		//		console.log(inventoryEmmisions)

		function flatten(a, b) {
			return a.concat(b);
		}

		var projectNames = {};
		Array.isArray(this.props.project.projects) &&
			this.props.project.projects.forEach((x) => {
				projectNames[x.projectSerial] = x.title;
			});

		//console.log(this.props.project.projects)
		//console.log(projectNames);

		const unretiredInventory = inventoryEmmisions
			.map((a) =>
				Object.entries(a.projects).map(([serial, x]) => ({
					year: a.year,
					count: x.unretiredInventory,
					projectSerial: serial,
					// not very efficient lookup
					//projectName: this.props.project.projects.find(x=>x.projectSerial===serial).title
				}))
			)
			.reduce(flatten, [])
			.filter((x) => x.count > 0)
			.map((x) => ({
				...x,
				// year: x.year,
				// count: x.count,
				// projectSerial: x.projectSerial,
				projectName: this.props.project.projects.find(
					(y) => y.projectSerial === x.projectSerial
				)?.title,
			}));

		//			console.log(unretiredInventory);

		const isRestricted =
			this.state.status !== ProjectStatusEnum.Draft &&
			this.state.status !== ProjectStatusEnum.Rejected &&
			!isInventoryOrOffsets;

		const required = function (text, show) {
			return (
				<React.Fragment>
					{text}
					{!readOnly && (show === undefined || show) && <i className="text-danger"> *</i>}
				</React.Fragment>
			);
		};

		var entities = {};

		for (var px in publicProjects) {
			const p = publicProjects[px];

			if (!entities[p.organization] && p.organizationSerial !== this.props.auth.user.certserialnumber) {
				entities[p.organization] = {
					name: p.organization,
					organizationLogo: p.organizationLogo,
					microsite: p.organizationSite,
					organizationSerial: p.organizationSerial,
				};
			}
		}

		return (
			<React.Fragment>
				<Prompt
					when={this.state.changesPending}
					message="You have unsaved changes, are you sure you want to leave?"
				/>
				<ContentBox
					title={
						<span>
							{displayTitle} {badge}
							{this.state.status === ProjectStatusEnum.Approved &&
								!isInventoryOrOffsets && (
									<ShareButton
										url={
											"https://" +
											window.location.hostname +
											/projects/ +
											this.state.projectSerial
										}
									/>
								)}
						</span>
					}
					subtitle={
						<React.Fragment>
							{!readOnly && <span color="white">* Indicates required field</span>}
						</React.Fragment>
					}
					width="12"
				>
					{this.state.status === ProjectStatusEnum.Rejected && (
						<Alert color="warning">{TextDictionary.ProjectRejected}</Alert>
					)}

					{this.state.status === ProjectStatusEnum.Submitted && (
						<Alert color="warning">{TextDictionary.ProjectSumbitted}</Alert>
					)}

					{!ownProject && <Alert>{TextDictionary.ProjectNotOwned}</Alert>}

					<form noValidate onSubmit={this.onSubmit}>
						<Row>
							<Col md="6">
								<fieldset disabled={readOnly}>
									<TextFieldGroup
										name="title"
										label={required(fieldPrefix + "Title")}
										onChange={this.onChange}
										value={this.state.title}
										error={errors.title}
									/>
									{isOffsets && (
										<TextFieldGroup
											name="offsetProvider"
											label={required("Offsets Provider")}
											onChange={this.onChange}
											value={this.state.offsetProvider}
											error={errors.offsetProvider}
											tooltip={TextDictionary.Tooltip.OffsetProvider}
										/>
									)}

									{isOffsets && (
										<SelectListGroup
											name="visibility"
											label={required("Visibility")}
											onChange={this.onChange}
											value={this.state.visibility}
											error={errors.visibility}
											options={[
												{ label: "Public", value: "Public" },
												{ label: "Private", value: "Private" },
											]}
											tooltip={TextDictionary.Tooltip.OffsetVis}
										></SelectListGroup>
									)}

									{isInventory && (
										<SelectListGroup
											name="overviewTracking"
											label={required("Track in Overview")}
											onChange={this.onChange}
											value={this.state.overviewTracking}
											error={errors.overviewTracking}
											options={[
												{ label: "Include", value: "Tracked" },
												{ label: "Exclude", value: "Hidden" },
											]}
											tooltip={TextDictionary.Tooltip.InvTrackOverview}
										></SelectListGroup>
									)}

									<TextAreaFieldGroup
										name="description"
										label={required(
											fieldPrefix + "Description (Max. 500 words)",
											!isInventoryOrOffsets
										)}
										onChange={this.onChange}
										value={this.state.description}
										error={errors.description}
									/>

									{!isInventoryOrOffsets && (
										<React.Fragment>
											<p className="h5">Project Tags</p>
											<div
												style={{
													display: "grid",
													gridTemplateColumns: "1fr 1fr",
													gridColumnGap: "1rem",
												}}
											>
												<SelectListGroup
													name="programType"
													value={this.state.programType}
													label={required("Program Type")}
													options={programTypeOptions}
													onChange={this.onChange}
													error={errors.programType}
													readOnly={
														(isSpecialProject && isDateSet) ||
														this.props.programType
													}
													tooltip={TextDictionary.Tooltip.ProgramType}
												/>
												<SelectListGroup
													name="projectType"
													value={this.state.projectType}
													label={required("Project Type")}
													options={typeOptions}
													onChange={this.onChange}
													error={errors.projectType}
													tooltip={TextDictionary.Tooltip.ProjectType}
												/>
												<SelectListGroup
													name="projectStatus"
													value={this.state.projectStatus}
													label={required("Project Stage")}
													options={statusOptions}
													onChange={this.onChange}
													error={errors.projectStatus}
													tooltip={TextDictionary.Tooltip.ProjectStatus}
												/>
												<SelectListGroup
													name="programOfActivities"
													value={this.state.programOfActivities}
													label={required("Program of Activities")}
													options={programOfActivitiesOptions}
													onChange={this.onChange}
													error={errors.programOfActivities}
													tooltip={
														TextDictionary.Tooltip.ProgramOfActivities
													}
												/>
												{this.state.projectStatus === "Proposed" && (
													<TextFieldGroup
														name="funding"
														placeholder=""
														value={this.state.funding}
														label={required(
															"Total Funding Required (Specify Currency)"
														)}
														onChange={this.onChange}
														style={{
															gridColumnStart: "1",
															gridColumnEnd: "3",
														}}
														error={errors.funding}
														tooltip={TextDictionary.Tooltip.Funding}
													/>
												)}
											</div>
										</React.Fragment>
									)}

									{!isInventory && (
										<React.Fragment>
											<div>
												<p className="h5">Location</p>
												<div>
													<TextFieldGroup
														name="address"
														placeholder="Address"
														value={this.state.address}
														label={required(
															"Civic Address",
															!isInventoryOrOffsets
														)}
														onChange={this.onChange}
														autoComplete="street-address"
														style={{
															gridColumnStart: "1",
															gridColumnEnd: "3",
														}}
														error={errors.address}
														tooltip={TextDictionary.Tooltip.Location}
													/>
												</div>
												<p className="h5">
													Map Location
													<Tooltip
														left={true}
														name="maplocaltion"
														content={TextDictionary.Tooltip.MapLocation}
													/>
												</p>
												<div
													style={{
														display: "grid",
														gridTemplateColumns: "1fr 1fr",
														gridColumnGap: "1rem",
													}}
												>
													<TextFieldGroup
														name="latitude"
														placeholder="xx.xxx"
														value={this.state.latitude}
														label={required(
															"Latitude",
															!isInventoryOrOffsets
														)}
														onChange={this.onChange}
														error={errors.latitude}
													/>
													<TextFieldGroup
														name="longitude"
														placeholder="xx.xxx
											"
														value={this.state.longitude}
														label={required(
															"Longitude",
															!isInventoryOrOffsets)}
														onChange={this.onChange}
														error={errors.longitude}
													/>
												</div>
											</div>
											<div>
												<Map
													className="project-map mb-3"
													center={[
														parseFloat(this.state.latitude) || 0,
														parseFloat(this.state.longitude) || 0,
													]}
													zoom={9}
													//zoomControl={false}
													scrollWheelZoom={false}
													boxZoom={false}
													keyboard={false}
													dragging={false}
													//touchZoom={false}
													doubleClickZoom={false}
												>
													<TileLayer
														url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
														attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
													/>

													<Control position="topright">
														<LocationPicker
															onChange={(e) =>
																this.locationUpdated(e)
															}
															latitude={this.state.latitude}
															longitude={this.state.longitude}
															disabled={readOnly}
														/>
													</Control>

													<Marker
														position={{
															lat:
																parseFloat(this.state.latitude) ||
																0,
															lng:
																parseFloat(this.state.longitude) ||
																0,
														}}
													/>
												</Map>
											</div>{" "}
										</React.Fragment>
									)}
									<div>
										<p className="h5">Contact Person</p>
										<div
											style={{
												display: "grid",
												gridTemplateColumns: "1fr 1fr",
												gridColumnGap: "1rem",
											}}
										>
											<TextFieldGroup
												name="fullName"
												placeholder="Full Name"
												value={this.state.fullName}
												label={required("Full Name", !isInventoryOrOffsets)}
												onChange={this.onChange}
												autoComplete="name"
												error={errors.fullName}
											/>
											<TextFieldGroup
												name="jobTitle"
												placeholder="Job Title"
												value={this.state.jobTitle}
												label={required("Job Title", !isInventoryOrOffsets)}
												onChange={this.onChange}
												error={errors.jobTitle}
											/>
											<TextFieldGroup
												name="email"
												placeholder="Email Address"
												value={this.state.email}
												label={required("Email", !isInventoryOrOffsets)}
												onChange={this.onChange}
												autoComplete="email"
												error={errors.email}
											/>
											<TextFieldGroup
												name="tel"
												placeholder="Phone Number"
												value={this.state.tel}
												label={required(
													"Phone Number",
													!isInventoryOrOffsets
												)}
												onChange={this.onChange}
												autoComplete="tel"
												error={errors.tel}
											/>
										</div>
									</div>
								</fieldset>
								<div>
									<label className="w-100">
										<span className="h5">{fieldPrefix}Timeline</span>
										<span> (Max. 20 years)</span>
										<Tooltip
											left={true}
											name="timeline"
											content={
												isInventory
													? TextDictionary.Tooltip.InvTimeline
													: TextDictionary.Tooltip.Timeline
											}
										/>
									</label>
									<Row className="pl-2 pr-2">
										<Col md="6" className="pl-2 pr-2">
											<fieldset disabled={readOnly}>
												{!isInventory ? (
													<DatePickerGroup
														name="startDate"
														label={required("Start Date")}
														onChange={this.timelineUpdated}
														value={this.state.startDate}
														error={errors.startDate}
													/>
												) : (
													<TextFieldGroup
														name="startDate"
														type="number"
														label={required("Start Year")}
														onChange={this.timelineYearUpdated}
														value={this.state.startDate.substring(0, 4)}
														error={errors.startDate}
													></TextFieldGroup>
												)}
											</fieldset>
										</Col>
										<Col md="6" className="pl-2 pr-2">
											<fieldset disabled={disableEndDate}>
												{!isInventory ? (
													<DatePickerGroup
														name="endDate"
														label={required("End Date")}
														onChange={this.timelineUpdated}
														value={this.state.endDate}
														error={errors.endDate}
													/>
												) : (
													<TextFieldGroup
														name="endDate"
														type="number"
														label={required("End Year")}
														onChange={this.timelineYearUpdated}
														value={this.state.endDate.substring(0, 4)}
														error={errors.endDate}
													></TextFieldGroup>
												)}
											</fieldset>
										</Col>
									</Row>
								</div>
							</Col>
							<Col md="6">
								<div className="mb-3">
									{!isInventory && (
										<fieldset disabled={readOnly}>
											<label className="w-100">
												<span className="h5">Images</span>
												<span> (Max. 5MB)</span>
											</label>

											<Row>
												<ImageCol>
													<FileUploadFieldGroup
														label={
															<React.Fragment>
																Featured View
															</React.Fragment>
														}
														name="featured"
														optional={isOffsets}
														onChange={this.onChange}
														file="images"
														value={this.state.featured}
														error={errors.featured}
														readOnly={readOnly}
														tooltip={
															TextDictionary.Tooltip.FeaturedImage
														}
													/>
												</ImageCol>
												<ImageCol>
													<FileUploadFieldGroup
														label="Project Site"
														name="projectSite"
														optional={true}
														onChange={this.onChange}
														file="images"
														value={this.state.projectSite}
														error={errors.projectSite}
														readOnly={readOnly}
													/>
												</ImageCol>
												<ImageCol>
													<FileUploadFieldGroup
														label="Situation Before"
														optional={true}
														name="before"
														onChange={this.onChange}
														file="images"
														value={this.state.before}
														error={errors.before}
														readOnly={readOnly}
													/>
												</ImageCol>
												<ImageCol>
													<FileUploadFieldGroup
														label="Project Activity"
														optional={true}
														name="activity"
														onChange={this.onChange}
														file="images"
														value={this.state.activity}
														error={errors.activity}
														readOnly={readOnly}
													/>
												</ImageCol>
												<ImageCol>
													<FileUploadFieldGroup
														label="Other"
														optional={true}
														name="otherImage"
														onChange={this.onChange}
														file="images"
														value={this.state.otherImage}
														error={errors.otherImage}
														readOnly={readOnly}
													/>
												</ImageCol>
												{this.state.programOfActivities ===
													"Program of Activities" && (
														<ImageCol>
															<FileUploadFieldGroup
																label="Map of Activites"
																name="mapOfActivities"
																onChange={this.onChange}
																file="images"
																value={this.state.mapOfActivities}
																error={errors.mapOfActivities}
																readOnly={readOnly}
																tooltip={
																	TextDictionary.Tooltip
																		.ProgramOfActivityImage
																}
															/>
														</ImageCol>
													)}
											</Row>
										</fieldset>
									)}
								</div>
								{isInventory || (
									<fieldset disabled={false}>
										<div>
											<label className="w-100 mb-3">
												<span className="h5">{fieldPrefix}Documents</span>
												<span> (Max. 25MB)</span>
											</label>

											<FileUploadFieldGroup
												label={
													<React.Fragment>
														{isOffsets
															? "Documentation"
															: "Project Plan"}
													</React.Fragment>
												}
												pdfOnly={!isOffsets}
												name="projectPlan"
												onChange={this.onChange}
												file="documents"
												value={this.state.projectPlan}
												error={errors.projectPlan}
												readOnly={readOnly}
												tooltip={
													isInventory
														? TextDictionary.Tooltip
															.GHGInventoryDocument
														: TextDictionary.Tooltip.ProjectPlanDocument
												}
											/>
											<FileUploadFieldGroup
												label="Financial Plan"
												pdfOnly={true}
												optional={this.state.projectStatus !== "Proposed"}
												name="financialPlan"
												onChange={this.onChange}
												file="documents"
												value={this.state.financialPlan}
												error={errors.financialPlan}
												readOnly={readOnly}
												tooltip={
													TextDictionary.Tooltip.FinancialPlanDocument
												}
											/>

											<FileUploadFieldGroup
												label={fieldPrefix + "Validation"}
												optional={true}
												name="projectValidation"
												pdfOnly={!isOffsets}
												onChange={this.onChange}
												file="documents"
												value={this.state.projectValidation}
												error={errors.projectValidation}
												readOnly={
													readOnly &&
													(readOnlyDocuments ||
														(this.props.project.project &&
															this.props.project.project.documents
																.projectValidation.url !== null))
												}
												tooltip={
													isInventory
														? undefined
														: TextDictionary.Tooltip.ValidationDocument
												}
											/>
											<FileUploadFieldGroup
												label={fieldPrefix + "Verification"}
												optional={true}
												name="verificationReport"
												pdfOnly={!isOffsets}
												onChange={this.onChange}
												file="documents"
												value={this.state.verificationReport}
												error={errors.verificationReport}
												readOnly={
													readOnly &&
													(readOnlyDocuments ||
														(this.props.project.project &&
															this.props.project.project.documents
																.verificationReport.url !== null))
												}
												tooltip={
													isInventory
														? undefined
														: TextDictionary.Tooltip
															.VerificationDocument
												}
											/>

											<FileUploadFieldGroup
												label="Other"
												optional={true}
												name="other1"
												onChange={this.onChange}
												file="documents"
												value={this.state.other1}
												error={errors.other1}
												readOnly={
													readOnly &&
													(readOnlyDocuments ||
														(this.props.project.project &&
															this.props.project.project.documents
																.other1.url !== null))
												}
												tooltip={
													isInventory
														? undefined
														: TextDictionary.Tooltip.OtherDocument
												}
											/>
											<FileUploadFieldGroup
												label="Other"
												optional={true}
												name="other2"
												onChange={this.onChange}
												file="documents"
												value={this.state.other2}
												error={errors.other2}
												readOnly={
													readOnly &&
													(readOnlyDocuments ||
														(this.props.project.project &&
															this.props.project.project.documents
																.other2.url !== null))
												}
											/>
										</div>
									</fieldset>
								)}
							</Col>
						</Row>

						<Row>
							<Col md={isInventory ? "6" : "12"}>
								<p className="h5 pt-4">
									{isInventory
										? "GHG Inventory"
										: isOffsets
											? "Purchased Offsets"
											: "Emission Reductions"}
								</p>
								{this.isErRangeValid(
									this.state.startDate.substring(0, 4),
									this.state.endDate.substr(0, 4)
								) ? (
									<EmissionReduction
										value={this.state.emissionReductions}
										error={errors.emissionReductions}
										onChange={this.onErChange}
										readOnly={readOnlyEmission}
										restricted={isRestricted}
										inventory={unretiredInventory}
										showSerial={!isInventoryOrOffsets}
										projectNames={projectNames}
										entities={entities}
										isOffsets={isOffsets}
										isTransferred={isTransferred}
									/>
								) : (
									<Alert color="danger">
										Please provide a valid project timeline to edit
										{isInventory ? " inventory" : " emission reductions"}.
										<br />
										{!isInventory &&
											"Note: Projects can span a maximum of 20 years."}
									</Alert>
								)}
							</Col>
							{/* Inventory Documents */}
							{isInventory && (
								<Col md="6">
									<fieldset disabled={false}>
										<div>
											<label className="w-100 mb-3 pt-4">
												<span className="h5">{fieldPrefix}Documents</span>
												<span> (Max. 25MB)</span>
											</label>
											{!this.isErRangeValid(
												this.state.startDate.substring(0, 4),
												this.state.endDate.substr(0, 4)
											) ? (
												<Alert color="danger">
													Please provide as starting and end year for your
													inventory timeline first.
												</Alert>
											) : (
												<React.Fragment>
													<div
														style={{
															display: "grid",
															gridTemplateColumns: "1fr 1.25fr",
														}}
														className="pb-2"
													>
														<p className="h6">Title</p>
														<p className="h6">File</p>
													</div>
													<div>
														{Object.keys(this.state.documents)
															.sort()
															.reverse()
															.map((internalName) => {
																const documentValue =
																	this.state.documents[
																	internalName
																	];

																const documentName =
																	internalName.replace(
																		"_inventory_",
																		"GHG Inventory "
																	);

																return (
																	<React.Fragment
																		key={internalName}
																	>
																		<FileUploadFieldGroup
																			label={documentName}
																			name={internalName}
																			onChange={
																				this
																					.onChangeDocument
																			}
																			file="documents"
																			value={documentValue}
																			error={
																				errors[internalName]
																			}
																			readOnly={readOnly}
																			optional={true}
																			tooltip={
																				TextDictionary
																					.Tooltip
																					.InventoryAnualDocument
																			}
																		/>
																	</React.Fragment>
																);
															})}
													</div>
												</React.Fragment>
											)}
										</div>
									</fieldset>
								</Col>
							)}
						</Row>

						{!readOnly && (
							<Row>
								{!isInventoryOrOffsets ? (
									<React.Fragment>
										<Col md="7">
											<Alert color="warning">
												{TextDictionary.SubmitNote}
											</Alert>
										</Col>
										<Col md="5">
											<Button
												color="secondary"
												className="mr-4"
												disabled={
													this.state.working_draft ||
													this.state.working_submit
												}
												//className="float-right"
												onClick={this.onDraftSubmit}
											>
												<SmallSpinner
													width="20px"
													show={this.state.working_draft}
												/>
												Save as draft
											</Button>
											<Button
												id="submit"
												color="primary"
												disabled={
													this.state.working_draft ||
													this.state.working_submit
												}
											>
												<SmallSpinner
													width="20px"
													show={
														this.state.working_draft ||
														this.state.working_submit
													}
												/>
												Submit
											</Button>

											<div>
												{Object.keys(errors).length ? (
													<p style={{ color: "red" }}>
														{this.props.errors.project
															? this.props.errors.project
															: TextDictionary.ProjectSubmitError}
													</p>
												) : null}
											</div>
										</Col>
									</React.Fragment>
								) : (
									<React.Fragment>
										<Col md="9" />
										<Col md="3">
											<Button
												id="submit"
												color="primary"
												disabled={
													this.state.working_draft ||
													this.state.working_submit
												}
											>
												<SmallSpinner
													width="20px"
													show={this.state.working_submit}
												/>
												Save
											</Button>

											<div>
												{Object.keys(errors).length ? (
													<p style={{ color: "red" }} id="error">
														{this.props.errors.project
															? this.props.errors.project
															: TextDictionary.ProjectSubmitError}
													</p>
												) : null}
											</div>
										</Col>
									</React.Fragment>
								)}
							</Row>
						)}

						{readOnly && !readOnlyEmission && (
							<Row>
								<Col md="7">
									{docsChanged || serializationRequired ? (
										<Alert color="warning">
											{TextDictionary.UpdateEmissionsRequireApproval}
										</Alert>
									) : (
										<Alert>{TextDictionary.UpdateEmissionsNoApproval}</Alert>
									)}
								</Col>
								<Col md="3">
									<Button
										color="primary"
										className="float-right"
										onClick={(e) => {
											e.preventDefault();
											this.props.updateEmissions(
												this.state.projectSerial,
												{
													EmissionReductions:
														this.state.emissionReductions,
													documents: {
														projectPlan: this.state.projectPlan,
														financialPlan: this.state.financialPlan,
														projectValidation:
															this.state.projectValidation,
														verificationReport:
															this.state.verificationReport,
														other1: this.state.other1,
														other2: this.state.other2,
													},
													EndDate: this.state.endDate,
												},
												this.props.auth.user.certserialnumber,
												this.props.history
											);
										}}
									>
										Update Emissions
									</Button>

									<div>
										{Object.keys(errors).length ? (
											<p style={{ color: "red" }}>
												{this.props.errors.project
													? this.props.errors.project
													: TextDictionary.ProjectSubmitError}
											</p>
										) : null}
									</div>
								</Col>
							</Row>
						)}
					</form>
				</ContentBox>
				{this.props.auth.user.isAdmin && (
					<ContentBox title="Admin Actions">
						{this.props.auth.user.isAdmin &&
							this.state.status === ProjectStatusEnum.Submitted && (
								<div>
									<Button
										onClick={(e) => {
											e.preventDefault();
											this.props.approveProject(this.state.projectSerial);
										}}
										className="float-right"
										color="primary"
									>
										Approve
									</Button>
									<Button
										onClick={(e) => {
											e.preventDefault();
											this.props.rejectProject(this.state.projectSerial);
										}}
										className="float-right"
										color="primary"
									>
										Reject
									</Button>
								</div>
							)}
						<div>
							<p>
								<Link to={`/projects/history/${this.state.projectSerial}`}>
									Show History
								</Link>
							</p>
						</div>

						{this.props.auth.user.isAdmin &&
							this.state.status === ProjectStatusEnum.Approved && (
								<div>
									<Button
										onClick={(e) => {
											e.preventDefault();
											this.props.reopenProject(this.state.projectSerial);
										}}
										color="primary"
									>
										Re-Open (Reset to Draft)
									</Button>
								</div>
							)}

						{this.props.auth.user.isAdmin && (
							<div>
								<Button
									onClick={(e) => {
										e.preventDefault();

										if (
											window.confirm(
												"Are you sure you want to delete the project?"
											)
										) {
											this.props
												.deleteProject(this.state.projectSerial)
												.then(this.props.history.push("/admin/projects"));
										}
									}}
									color="primary"
								>
									Delete
								</Button>
							</div>
						)}

						<div>
							<div>
								Featured:{" "}
								{isFeatured
									? "This projects IS featured"
									: "This project is NOT featured"}
								<Button
									onClick={(e) => {
										e.preventDefault();
										this.props.featureProject(
											this.state.projectSerial,
											!isFeatured
										);
									}}
									//className="float-right"
									color="primary"
								>
									{isFeatured ? "Remove Featured" : "Set Featured"}
								</Button>
							</div>
							<div>
								Archived:{" "}
								{isArchived
									? "This projects IS archived"
									: "This project is NOT archived"}
								<Button
									onClick={(e) => {
										e.preventDefault();
										this.props.archiveProject(
											this.state.projectSerial,
											!isArchived
										);
									}}
									//className="float-right"
									color="primary"
								>
									{isArchived ? "Remove Archived" : "Set Archived"}
								</Button>
							</div>
							{!this.isNewProject() && !editOverwrite && (
								<Button
									color="primary"
									onClick={() => {
										this.setState({ editOverwrite: true });
									}}
								>
									Edit Project
								</Button>
							)}
						</div>
					</ContentBox>
				)}
			</React.Fragment>
		);
	}
}

CreateProject.propTypes = {
	project: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	project: state.project,
	errors: state.errors,
	auth: state.auth,
	draft: state.drafts,
});

export default connect(mapStateToProps, {
	createProject,
	submitProject,
	approveProject,
	rejectProject,
	reopenProject,
	deleteProject,
	featureProject,
	archiveProject,
	updateEmissions,
	createERTable,
	getUserProjectTokens,
	getPublicProjects,
	getDraft,
	redirect,
	clearErrors,
	clearOneError,
	getUserProjects,
})(withRouter(CreateProject));
